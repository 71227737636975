<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="content">
      <div class="cons">
        <div class="sctptitle">
          <el-button type="primary" @click="clicksctp">上传图片</el-button>
        </div>
        <div class="sctplist">
          <el-table :data="tableData" border style="width: 100%"  v-loading="loading" :element-loading-text="loadingtext">
            <!-- <el-table-column prop="pictureName" label="图片名称" /> -->
            <el-table-column prop="pictureUrl" label="缩略图" width="150">
              <template #default="scope">
                <div class="imgslt">
                  <el-image
                    :src="scope.row.pictureUrl"
                    style="width: 80px; height: 80px"
                    fit="fill"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" sortable label="提交时间" />
            <el-table-column prop="status" sortable label="查重状态">
              <template #default="scope">
                <el-tag
                  class="ml-2"
                  :type="
                    scope.row.status == 1
                      ? 'info'
                      : scope.row.status == 2
                      ? 'warning'
                      : scope.row.status == 8
                      ? 'danger'
                      : scope.row.status == 9
                      ? ''
                      : 'success'
                  "
                  >{{
                    scope.row.status == 1
                      ? "未查重"
                      : scope.row.status == 2
                      ? "查重中"
                      : scope.row.status == 8
                      ? "查重失败"
                      : scope.row.status == 9
                      ? "查重完成"
                      : "状态未知"
                  }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                  v-if="scope.row.status == 1"
                  size="small"
                  type="info"
                  @click="clickkscc(scope.row.id)"
                  >开始查重</el-button
                >
                <el-button
                  v-if="scope.row.status == 2"
                  size="small"
                  type="warning"
                  @click="clickckzt(scope.row.id)"
                  >更新状态</el-button
                >
                <el-button
                  v-if="scope.row.status == 8"
                  size="small"
                  type="danger"
                  @click="clickkscc(scope.row.id)"
                  >重新查重</el-button
                >
                <el-button
                  v-if="scope.row.status == 9"
                  size="small"
                  type="primary"
                  @click="clickckjg(scope.row.id)"
                  >查看结果</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="sctplist-fy">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totals"
              :current-page="currentPage"
              @current-change="fanye"
            />
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

let tableData = reactive([]);
// loading
let loading = ref(false);
let loadingtext=ref('')
// 分页
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(10);
// 分页
const fanye = (val) => {
  page.value = val;
  currentPage.value=val
  getlist()
};
// 获取数据
const getlist = () => {
  post("/pictureCheck/page", {
    page: page.value,
  }).then((res) => {
    // console.log(res);
    tableData.length=0
    tableData.push(...res.result.items);
    totals.value=res.result.total
  });
};
getlist();

// 开始查重
const clickkscc = (id) => {
  loading.value = true;
  loadingtext.value='查重中...'
  get("/pictureCheck/submitCheck/" + id).then((res) => {
    loading.value = false;
    ElMessage({
      message: "开始查重",
      duration: 1000,
      onClose: () => {
        getlist();
        // location.reload()
      },
    });
  }).catch((err) => {
      loading.value = false;
    });
};
// 更新
const clickckzt=id=>{
  loading.value = true;
  loadingtext.value='查重中...'
  get("/pictureCheck/checkStatus/" + id).then((res) => {
    loading.value = false;
    ElMessage({
      message: "状态已更新",
      duration: 1000,
      onClose: () => {
        getlist();
        // location.reload()
      },
    });
  }).catch((err) => {
      loading.value = false;
    });
}
// 查看结果
const clickckjg=id=>{
  router.push({
    path: "/tpcc/res",
    query: {
      id:id
    },
  });
}

// 跳转上传
const clicksctp = () => {
  router.push({
    path: "/tpcc/sbu",
    query: {},
  });
};
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.sctptitle {
  margin-bottom: 20px;
}
.sctplist-fy{
  margin-top: 20px;
}
</style>
